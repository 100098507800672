













































import { Component, Vue } from 'vue-property-decorator';
import { readEmpresas } from '@/store/empresa/getters';
import { dispatchDeleteEmpresa, dispatchGetEmpresas } from '@/store/empresa/actions';
import { IEmpresa } from '@/interfaces/empresa';
import ModalConfirmacao from '@/components/ModalConfirmacao.vue';

@Component({
  components: {
    ModalConfirmacao,
  },
})
export default class ListarEmpresas extends Vue {
  public modalExclusaoAtivo: boolean = false;
  public empresaExclusao;

  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'center',
    },
    {
      text: 'Nome',
      sortable: true,
      value: 'nome',
      align: 'left',
    },
    {
      text: 'Ações',
      align: 'center',
    },
  ];

  public options = {
    rowsPerPage: 10,
  };

  get empresas() {
    return readEmpresas(this.$store);
  }

  public abrirModalExclusao(empresa: IEmpresa) {
    this.empresaExclusao = empresa;
    this.modalExclusaoAtivo = true;
  }

  public async confirmarExclusao() {
    const excluiu = await dispatchDeleteEmpresa(this.$store, this.empresaExclusao);
    this.modalExclusaoAtivo = false;
  }

  public async mounted() {
    await dispatchGetEmpresas(this.$store);
  }
}
